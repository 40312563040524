<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="w-100 store">
		<b-card-group id="home">
			<b-card
				id="storeBackground"
				class="border-0 bg-transparent mb-0">
				<div
					:style="windowWidth === 'xs' ? 'min-height: 90vh' : 'min-height: 90vh'"
					:class="['md'].includes(windowWidth) ? 'mt-4 py-4' : ''"
					class="row">
					<div class="col my-auto">
						<div class="text-center">
							<!--<big-countdown
								v-if="isBetweenInterval"
								:hours="hours.toString()"
								:minutes="minutes.toString()"
								:seconds="seconds.toString()" />-->
							<div
								style="font-weight: 600;"
								:class="['xs','sm'].includes(windowWidth) ? 'h1' : 'big-label '"
								class="text-white">
								{{ translate('header') }}
							</div>
							<div
								style="font-weight: 600;"
								class="mb-5 h3 text-white">
								{{ translate('subheader') }}
							</div>
							<div class="row">
								<div class="col-12">
									<div
										v-if="!$user.auth()"
										:style="buttonClass"
										class="btn m-2 pointer btn-store-member w-auto"
										:class="['xs', 'sm'].includes(windowWidth) ? 'p-2' : 'p-2'"
										@click="becomeMember()">
										<span class="align-middle">
											{{ translate('become_member') }}
										</span>
									</div>
								</div>
								<div class="col-12">
									<div
										v-if="!$user.auth() && !earlyLaunch && showBuyNow"
										:style="buttonClass"
										class="btn m-2 pointer btn-store-customer w-auto"
										:class="['xs', 'sm'].includes(windowWidth) ? 'p-2' : 'p-2'"
										@click="becomeCustomer()">
										<span class="align-middle">
											{{ translate('become_customer') }}
										</span>
									</div>
								</div>
								<div class="col-12">
									<div
										v-if="$user.auth() && $can('pickup_store', 'view')"
										style="max-width: 420px"
										class="btn p-3 m-2 pointer btn-primary rounded-pill w-auto"
										@click="$router.push({ name: 'PSStore' })">
										<h4 class="m-0">
											{{ translate('physical_store') }}
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</b-card>
		</b-card-group>
		<b-card-group
			v-if="showPublicStore && !isAdminOrCorporate && !earlyLaunch && !$user.details().hide_store"
			id="our-products">
			<b-card class="border-0 bg-white mb-0">
				<store-products-banner
					:products-import="[TN, LT]"
					:products-reference="data"
					:background-style="['xs', 'sm'].includes(windowWidth) ? 'background-size: auto; background-position: 35% 0%' : 'background-size: cover; background-position: bottom left;'"
					:show-detail-button="[true, true]"
					:show-price="[false, false]" />
				<!-- Tickets and Videos -->
				<div
					class="row pt-5 px-5 mx-auto container">
					<div class="col">
						<template v-if="data.length > 0">
							<div
								class="row mb-5">
								<div class="col">
									<div class="text-center">
										<div
											:class="windowWidth === 'xs' ? 'h3' : 'big-label'"
											class="font-weight-bold text-primary">
											{{ translate('biohacking_solutions') }}
										</div>
									</div>
								</div>
							</div>
							<div
								v-if="hasData() && !loadingProducts"
								class="text-center">
								<store-product
									:products="data"
									:loading="addProductsLoading"
									@add_product="addProduct" />
								<div v-if="thereIsExchange">
									<p class="exchange-text text-center small mt-4">
										{{ translate('exchange_disclaimer') }}
									</p>
								</div>
							</div>
						</template>
					</div>
				</div>
				<div
					v-if="data.length > 0 || isAgencySite"
					class="row">
					<store-products-banner
						v-if="isAgencySite && !loadingProducts"
						:products-import="[TN, LT]"
						:products-reference="data"
						:background-style="['xs', 'sm'].includes(windowWidth) ? 'background-size: auto; background-position: 35% 0%' : 'background-size: cover; background-position: bottom left;'"
						:show-detail-button="[true, true]"
						:show-price="[false, false]" />
				</div>
				<is-loading
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="loadingProducts"
					class="mx-auto" />
				<div class="border border-bottom-0 border-x-0 border-light" />
				<lazy
					v-if="!ONLY_TUUN_COUNTRIES.includes(country) && hasData()"
					:min-height="vipPack || freeEvents.length > 0 || TICKET || TICKET2 ? 300 : 0">
					<store-products-banner
						reversed-background
						:products-import="plosBundle ? [vipPack, plosBundle] : [vipPack]"
						:products-reference="data"
						:show-detail-button="[true, true]"
						:show-price="[true, false]" />
					<!-- Free event tickets -->
					<free-tickets-banner
						v-for="(freeEvent, i) of freeEvents"
						:key="i"
						:event-code-name="freeEvent" />
					<!-- event tickets -->
					<store-products-banner
						v-if="TICKET !== null"
						:products-import="[TICKET]"
						:products-reference="data"
						:show-detail-button="[true]"
						:show-price="[false]" />
					<store-products-banner
						v-if="TICKET2 !== null"
						:products-import="[TICKET2]"
						:products-reference="data"
						:show-detail-button="[true]"
						:show-price="[true]"
						background-style="background-size: cover; background-position: top;" />
				</lazy>
				<!-- Video section -->
				<lazy min-height="200">
					<div class="row mx-auto py-4">
						<div class="col-12 py-5">
							<p
								:class="windowWidth === 'xs' ? 'h3' : 'h2'"
								class="text-center font-weight-bold bolder-text text-primary">
								{{ translate('steps_community') }}
							</p>
						</div>
						<div
							v-for="(step, index) in videoSteps"
							:key="index"
							class="col-12 col-md-4 text-center p-0">
							<div class="position-relative">
								<div class="black-overlay w-100 h-100 position-absolute">
									<b-button
										v-b-modal="`modal-${index}`"
										variant="link"
										class="h-100">
										<!-- <i class="far fa-play-circle" /> -->
										<img
											:src="require(`@/assets/images/common/playIcon.svg`)"
											fluid
											alt="Responsive image"
											class="w-50 pointer play-icon">
									</b-button>
								</div>
								<b-img
									:src="`${S3_PATH}/assets/videos/${theme}/step${step}.png?v=1`"
									fluid
									alt="Responsive image"
									class="w-100" />
							</div>
							<div class="p-4">
								<p class="h4 font-weight-bold text-primary">
									{{ translate(`step_title`, {step}) }}
								</p>
								<p
									class="h5 text-muted"
									v-html="translate(`step_${step}_description`)" />
							</div>
							<b-modal
								:id="`modal-${index}`"
								modal-class="media-modal"
								hide-footer
								centered
								size="lg">
								<div>
									<b-embed
										type="iframe"
										aspect="16by9"
										:src="`${videoLanguageUrl[step]}`"
										allow="autoplay; fullscreen"
										allowfullscreen />
								</div>
							</b-modal>
						</div>
					</div>
				</lazy>
				<!-- Swag Products -->
				<div>
					<lazy
						v-if="hasSwagProducts && !$can('pickup_store', 'view')"
						class="row mx-auto py-4"
						min-height="300">
						<div class="col-12 py-5">
							<p
								:class="windowWidth === 'xs' ? 'h3' : 'h2'"
								class="text-center font-weight-bold bolder-text text-primary">
								{{ translate('swag_products') }}
							</p>
						</div>
						<div
							:class="['xs','sm', 'md'].includes(windowWidth) ? ' px-1' : 'px-4'"
							class="row mx-auto container">
							<div
								class="w-100 mx-auto"
								:class="['xs','sm'].includes(windowWidth) ? 'scrolling-wrapper' : 'row justify-content-around'">
								<div
									v-for="(item, index) in swagProductsData"
									:key="index"
									class="text-center my-3"
									:class="['xs','sm'].includes(windowWidth) ? 'd-inline-block' : 'col-md-4 col-lg-3 d-flex flex-column mb-5'">
									<lazy>
										<img
											:src="item.image"
											:class="['xs', 'sm'].includes(windowWidth) ? 'img-max-width' : 'mh-100'"
											:style="!['xs', 'sm'].includes(windowWidth) ? 'height: 200px;' : ''"
											class="mx-auto mb-3 px-2 pointer img-fluid"
											@click="$router.push({ name: 'ProductDetails', params: { productName: item.product_name } })">
									</lazy>
									<div class="mt-2">
										<p class="h6 text-muted bolder-title">
											{{ translate(item.code_name) }}
										</p>
										<p class="h4">
											{{ getProductPrice(item) }}
										</p>
										<!-- <p
											v-if="item.exchange"
											class="exchange-text h6">
											{{ item.exchange_price }}
										</p> -->
										<button
											type="button"
											:class="['md'].includes(windowWidth) ? ' w-75' : 'w-50'"
											class="btn btn-rounded mt-3 branded-button-outline"
											@click="$router.push({ name: 'ProductDetails', params: { productName: item.product_name } })">
											{{ translate('see_more') }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</lazy>
				</div>
				<!-- Book section -->
				<lazy>
					<store-products-banner
						:products-import="[ON]"
						:show-price="[false]" />
				</lazy>
			</b-card>
		</b-card-group>
		<request-sponsor-modal
			v-model="showStoreModal"
			:redirect-type="redirectType" />
	</div>
</template>
<script>

import { sidebarCssClasses } from '@coreui/vue/src/shared/classes';
import cookie from 'vue-cookie';
import { META_DESCRIPTION } from '@/settings/Meta';
import {
	Store, Validations, Products, Purchase,
} from '@/translations';
import { MIN_QTY, MAX_QTY } from '@/settings/Cart';
import { SHOW_FROM_DATE, END_DATE, COUNTDOWN_TIMEZONE } from '@/settings/Countdown';
import { S3_PATH } from '@/settings/Images';
import { distributor } from '@/settings/Roles';
import EventBus from '@/util/eventBus';
import { trackEvent } from '@/util/GoogleTagManager';
import ProductMix from '@/mixins/Products';
import WindowSizes from '@/mixins/WindowSizes';
import EarlyLaunch from '@/mixins/EarlyLaunch';
import FreeTickets from '@/mixins/FreeTickets';
import Cart from '@/util/Cart';
import StoreProduct from './components/StoreProduct';
import StoreProductsBanner from './components/StoreProductsBanner';
import FreeTicketsBanner from './components/FreeTicketsBanner';
import Lazy from '@/components/Lazy';
import { ONLY_TUUN_COUNTRIES } from '@/settings/Register';
import { ONLY_MEMBERS_COUNTRIES } from '@/settings/Country';
import PublicStore from '@/mixins/PublicStore';
import RequestSponsorModal from '@/components/RequestSponsorModal';
// import Countdown from '@/mixins/Countdown';
// import BigCountdown from './components/BigCountdown';

export default {
	name: 'Store',
	metaInfo() {
		return {
			meta: [
				{ vmid: 'description', name: 'description', content: META_DESCRIPTION.store },
			],
		};
	},
	messages: [Store, Validations, Products, Purchase],
	components: {
		// BigCountdown,
		StoreProductsBanner,
		StoreProduct,
		FreeTicketsBanner,
		Lazy,
		RequestSponsorModal,
	},
	mixins: [ProductMix, WindowSizes, EarlyLaunch, FreeTickets, PublicStore],
	data() {
		return {
			addProducts: new Cart(),
			createCart: new Cart(),
			alert: new this.$Alert(),
			MIN_QTY,
			MAX_QTY,
			distributor,
			S3_PATH,
			clicked: 0,
			theme: process.env.VUE_APP_THEME,
			videoSteps: 3,
			countdownStart: SHOW_FROM_DATE,
			countdownEnds: END_DATE,
			countdownTimezone: COUNTDOWN_TIMEZONE,
			videoURL: {
				en: { // English version
					1: 'https://player.vimeo.com/video/461983332',
					2: 'https://player.vimeo.com/video/809848435',
					3: 'https://player.vimeo.com/video/860354122',
				},
				es: { // Spanish version
					1: 'https://player.vimeo.com/video/472618597',
					2: 'https://player.vimeo.com/video/818370591',
					3: 'https://player.vimeo.com/video/860354122',
				},
				ja: { // Japanese version
					1: 'https://player.vimeo.com/video/461983332',
					2: 'https://player.vimeo.com/video/809848435',
					3: 'https://player.vimeo.com/video/860354122',
				},
			},
			ONLY_TUUN_COUNTRIES,
			ONLY_MEMBERS_COUNTRIES,
		};
	},
	computed: {
		videoLanguageUrl() {
			return this.videoURL[this.language] ? this.videoURL[this.language] : this.videoURL.en;
		},
		addProductsLoading() {
			return !!this.addProducts.data.loading;
		},
		buttonClass() {
			return	{
				'min-width': ['xs', 'sm'].includes(this.windowWidth) ? '90%' : '450px',
				'min-height': !['xs', 'sm'].includes(this.windowWidth) ? '64px' : '',
				'font-size': !['xs', 'sm'].includes(this.windowWidth) ? '24px' : '',
				'text-align': 'center',
			};
		},
		data() {
			try {
				const products = [];
				this.products.forEach((item) => {
					let { exchange_price: exchangePrice } = item.attributes;
					let price = this.getProductPrice(item.attributes);

					if (this.hasDiscount(item)) {
						price = this.discount(item).value;
						exchangePrice = this.discount(item).exchange_price;
					}

					products.push({
						image: item.attributes.image,
						code_name: item.attributes.code_name,
						decoded_name: item.attributes.decoded_name,
						sku: item.attributes.sku,
						price,
						priceAmount: item.attributes.price_amount,
						quantity: 1,
						exchange: item.attributes.exchange,
						exchangePrice,
						has_configurations: item.attributes.has_configurations,
						thumbnail: item.attributes.thumbnail,
						detailsLink: item.attributes.details_link,
					});
				});
				return products;
			} catch (error) {
				return [];
			}
		},
		// Countdown properties
		// countdownBackground() {
		// 	if (this.countdownDone) {
		// 		return {
		// 			background: `url(${this.anniversaryBg})`,
		// 			'background-size': 'cover',
		// 			'border-radius': '0px',
		// 			'background-position': 'top left',
		// 		};
		// 	}
		// 	return {};
		// },
		// anniversaryBg() {
		// 	return require('@/assets/images/themes/Velovita/background_store_anniversary.png'); // eslint-disable-line global-require
		// },
		thereIsExchange() {
			const theresExchange = this.products.find((item) => item.attributes.exchange);
			return typeof theresExchange !== 'undefined';
		},
		otherProductsData() {
			try {
				const products = [];
				this.otherProducts.forEach((item) => {
					let { price } = item.attributes;
					let exchangePrice = item.attributes.exchange_price;
					if (this.hasDiscount(item)) {
						price = this.discount(item).value;
						exchangePrice = this.discount(item).exchange_price;
					}

					products.push({
						image: item.attributes.image,
						sku: item.attributes.sku,
						code_name: item.attributes.code_name,
						price,
						price_amount: item.attributes.price_amount,
						quantity: 1,
						exchange: item.attributes.exchange,
						exchangePrice,
						decoded_name: item.attributes.decoded_name,
						thumbnail: item.attributes.thumbnail,
						selectable: item.attributes.selectable,
						sold_out: item.attributes.sold_out,
					});
				});
				return products;
			} catch (error) {
				return [];
			}
		},
		wearablesProductsData() {
			try {
				const products = [];
				this.wearablesProducts.forEach((item) => {
					let { price } = item.attributes;
					let exchangePrice = item.attributes.exchange_price;
					if (this.hasDiscount(item)) {
						price = this.discount(item).value;
						exchangePrice = this.discount(item).exchange_price;
					}

					products.push({
						image: item.attributes.image,
						sku: item.attributes.sku,
						code_name: item.attributes.code_name,
						price,
						quantity: 1,
						exchange: item.attributes.exchange,
						exchangePrice,
						decoded_name: item.attributes.decoded_name,
						thumbnail: item.attributes.thumbnail,
						selectable: item.attributes.selectable,
					});
				});
				return products;
			} catch (error) {
				return [];
			}
		},
		litesProductsData() {
			try {
				const products = [];
				this.litesProducts.forEach((item) => {
					let { price } = item.attributes;
					let exchangePrice = item.attributes.exchange_price;
					if (this.hasDiscount(item)) {
						price = this.discount(item).value;
						exchangePrice = this.discount(item).exchange_price;
					}

					products.push({
						image: item.attributes.image,
						sku: item.attributes.sku,
						code_name: item.attributes.code_name,
						price,
						price_amount: item.attributes.price_amount,
						quantity: 1,
						exchange: item.attributes.exchange,
						exchangePrice,
						decoded_name: item.attributes.decoded_name,
						thumbnail: item.attributes.thumbnail,
						selectable: item.attributes.selectable,
						sold_out: item.attributes.sold_out,
					});
				});
				return products;
			} catch (error) {
				return [];
			}
		},
		booksProductsData() {
			try {
				const products = [];
				this.booksProducts.forEach((item) => {
					if (item.attributes.code_name !== 'ON1001') { //	Skip Organic Networker - Soft cover
						let { price } = item.attributes;
						let exchangePrice = item.attributes.exchange_price;
						if (this.hasDiscount(item)) {
							price = this.discount(item).value;
							exchangePrice = this.discount(item).exchange_price;
						}

						products.push({
							image: item.attributes.image,
							sku: item.attributes.sku,
							code_name: item.attributes.code_name,
							price,
							price_amount: item.attributes.price_amount,
							quantity: 1,
							exchange: item.attributes.exchange,
							exchangePrice,
							decoded_name: item.attributes.decoded_name,
							thumbnail: item.attributes.thumbnail,
							selectable: item.attributes.selectable,
							sold_out: item.attributes.sold_out,
						});
					}
				});
				return products;
			} catch (error) {
				return [];
			}
		},
		swagProductsData() {
			try {
				const products = [];
				this.swagProducts.forEach((item) => {
					let { price } = item.attributes;
					let exchangePrice = item.attributes.exchange_price;
					if (this.hasDiscount(item)) {
						price = this.discount(item).value;
						exchangePrice = this.discount(item).exchange_price;
					}
					products.push({
						image: item.attributes.image,
						sku: item.attributes.sku,
						code_name: item.attributes.code_name,
						price,
						quantity: 1,
						exchange: item.attributes.exchange,
						exchangePrice,
						product_name: item.attributes.decoded_name,
					});
				});
				return products;
			} catch (error) {
				return [];
			}
		},
		CB() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'CB');
				if (!this.showComboBanner(product, 2)) {
					return null;
				}
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-white',
					descriptionClass: 'font-weight-normal text-white',
				};

				return product;
			} catch (e) {
				return null;
			}
		},
		TP() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'TP');
				if (!this.showComboBanner(product, 3)) {
					return null;
				}
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-white',
					descriptionClass: 'font-weight-normal text-white',
					backgroundImg: 'tp-background-blue.jpg',
				};

				return product;
			} catch (e) {
				return null;
			}
		},
		TPP() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'TPP');
				if (!this.showComboBanner(product, 4)) {
					return null;
				}
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-white',
					descriptionClass: 'font-weight-normal text-white',
				};

				return product;
			} catch (e) {
				return null;
			}
		},
		PTP() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'PTP');
				if (!this.showComboBanner(product, 5)) {
					return null;
				}
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-white',
					descriptionClass: 'font-weight-normal text-white',
					backgroundImg: 'ptp-background-purple.png',
				};

				return product;
			} catch (e) {
				return null;
			}
		},
		VP() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'VP');
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-white',
					descriptionClass: 'text-white',
				};
				return product;
			} catch (e) {
				return null;
			}
		},
		VC() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'VC');
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-primary',
					descriptionClass: 'font-weight-normal',
				};
				return product;
			} catch (e) {
				return null;
			}
		},
		ON() {
			try {
				const product = this.booksProductsData.find((item) => item.sku === 'ON');
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-white',
					descriptionClass: 'text-white',
				};
				return product;
			} catch (e) {
				return null;
			}
		},
		TN() {
			try {
				const product = this.wearablesProductsData.find((item) => item.sku === 'TN');
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description_short`),
					titleClass: 'text-primary',
					descriptionClass: 'font-weight-normal',
					buttonClass: 'branded-button',
				};
				return product;
			} catch (e) {
				return null;
			}
		},
		LT() {
			try {
				const product = this.litesProductsData.find((item) => item.sku === 'LT');
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-primary',
					descriptionClass: 'font-weight-normal',
					buttonClass: 'branded-button',
					backgroundImg: 'LT.png',
				};
				return product;
			} catch (e) {
				return null;
			}
		},
		vipPack() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'PK-VIP');
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-primary',
					descriptionClass: 'font-weight-normal',
					buttonClass: 'branded-button',
					backgroundImg: 'TN.jpg',
				};
				return product;
			} catch (e) {
				return null;
			}
		},
		plosBundle() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'CB-PL');
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description`),
					titleClass: 'text-primary',
					descriptionClass: 'font-weight-normal',
					buttonClass: 'branded-button',
					backgroundImg: 'TN.jpg',
				};
				return product;
			} catch (e) {
				return null;
			}
		},
		TICKET() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'TK-VIT24');
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description_short`),
					backgroundImg: 'TK-VIT24.png',
					titleClass: 'text-success',
					// descriptionClass: 'font-weight-normal text-white',
				};

				return product;
			} catch (e) {
				return null;
			}
		},
		TICKET2() {
			try {
				const product = this.otherProductsData.find((item) => item.sku === 'TK-AC24');
				product.bannerContent = {
					description: this.translate(`${product.code_name}_description_short`),
					backgroundImg: 'TK-AC24.png',
					titleClass: 'text-success',
					// descriptionClass: 'font-weight-normal text-white',
				};

				return product;
			} catch (e) {
				return null;
			}
		},
	},
	watch: {
		country(newVal, oldVal) {
			let timeout = 0;
			if (oldVal === null) {
				timeout = 1500;
			}
			setTimeout(() => {
				if (this.showPublicStore) {
					this.prodData.getProducts(this.getStoredCountry());
				}
			}, timeout);
		},
	},
	created() {
		if (typeof this.$user.details().package === 'string') {
			this.currentPackage = this.$user.details().package;
		}
		sidebarCssClasses.map((item) => document.body.classList.remove(item));

		// start countdown
		// this.useSeconds = true;
		// this.timezone = this.countdownTimezone;
		// this.interval = 1000;
		// this.startTimer();
	},
	mounted() {
		EventBus.$emit('storeViewMounted');
	},
	methods: {
		// startTimer() {
		// 	if (!this.countdownEnds) {
		// 		return;
		// 	}
		// 	this.countdown(this.countdownEnds, this.countdownStart);
		// },
		getProductOptions(product) {
			const options = { image: product.thumbnail };
			return options;
		},
		scrollTo(id) {
			EventBus.$emit('scrollTo', id);
		},
		async addProduct(products) {
			if (!this.addProductsLoading) {
				try {
					await this.addProducts.addCartProducts(cookie.get('cart_id'), { products, sponsor_id: this.$replicated.replicatedSponsor() });
					trackEvent(this.$gtm, 'productQtyUpdated'); // TODO: track product quantity and sku
					EventBus.$emit('updateLayoutStoreTotals');
				} catch (e) {
					if (typeof this.addProducts.errors.errors.products !== 'undefined') {
						let response = '';
						this.addProducts.errors.errors.products.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 4000 });
					} else {
						this.alert.toast('error', this.translate('default_error_message'));
					}
				}
			}
		},
		showComboBanner(product, requiredProducts) {
			const selectableProducts = product.selectable.products[this.country] ?? product.selectable.products.default;
			const availableProducts = this.data.reduce((accum, mainProduct) => accum + selectableProducts.includes(mainProduct.decoded_name), 0);
			return availableProducts >= requiredProducts;
		},
		// hasData() was changed from being a computed property due to issues with reactivity
		// TODO: Identify and fix the issue with reactivity and change it back to computed property
		hasData() {
			const response = this.data.length;
			return !!response;
		},
	},
};
</script>

<style scoped>
	@import url('https://fonts.googleapis.com/css?family=Montserrat');

	.store  html body div {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 600 !important;
	}

	p h5 {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 400 !important;
	}
	.img-max-width{
		max-width: 90%;
	}
	.btn-rounded {
		border-radius: 25px;
	}
	.btn-store-customer {
		/* max-width: 220px; */
		color: #551e1a;
		background-color: white;
		font-size: 20px;
		border-color: #551e1a;
		font-weight: bold;
	}
	.btn-store-customer:hover {
		background-color: #e6e6e6;
		border-color: #612A23;
		transition: 0.7s;
	}
	/* Background buttons */
	.btn-store-member {
		/* max-width: 220px; */
		color: white;
		background-color: #7d362d;
		font-size: 20px;
	}
	.btn-store-member:hover {
		background-color: #612A23;
		color: #e6e6e6;
		transition: 0.7s;
	}
</style>
<style>
	.big-label{
		font-size: 55px;
	}
	.custom-btn.custom-white-btn{
		border: 1px solid white;
		width: auto;
		border-radius: 25px;
		background: transparent;
		color: white;
	}
	.custom-btn.custom-white-btn:hover{
		background: white;
		color: black;
	}

	.custom-btn.custom-primary-btn{
		width: 150px;
		border-radius: 25px;
	}

	.custom-hr{
		opacity: 0.1;
		border-color: white;
	}

	.vnis__input.vnis-custom-input-width{
		width: 90px;
	}

	.vnis.vnis-custom-dimensions {
		width: 170px;
		height: 30px;
	}
	.black-overlay{
		background: rgba(23, 23, 25, 0.5);
	}
	.play-icon{
		min-width: 75px;
		transition: all .3s ease-in-out;
	}
	.play-icon:hover{
		transform: scale(1.1);
	}
	.bolder-text {
		-webkit-text-stroke: 1px #DF703D;
	}
</style>
